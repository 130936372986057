import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  keyCheck;
  keyName;
  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 8,
          header: { cols: 8, rows: 1 },
          product: { cols: 8, rows: 3 },
          filler: { cols: 0, rows: 1 },
          prod2: { cols: 8, rows: 3 },
        };
      }

      return {
        // 2 mini card layout
        // columns: 6,
        // miniSelector: { cols: 2, rows: 1 },
        // miniCard: { cols: 1, rows: 1 },
        // chart: { cols: 2, rows: 2 },
        // map: { cols: 4, rows: 4 },
        // wildfireCard: {cols: 2, rows:2}
        columns: 8,
        header: { cols: 8, rows: 1 },
        product: { cols: 4, rows: 3 },
        prod2: { cols: 4, rows: 3 },
        filler: { cols: 2, rows: 3 },
        productLast: { cols: 4, rows: 3 },

      };
    })
  );
  constructor(private breakpointObserver: BreakpointObserver, public auth: AuthService, private myRoute: Router) { }
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {

    })
    this.auth.checkAccountIsValid();
  }

  navigate(loc) {
    switch (loc) {
      case 'kwg':
        window.location.href = environment.interfaceUrl
        break;
      case 'kwg-home':
        window.open("http://knowwheregraph.org", "_blank")
        break;
      case 'api':
        this.myRoute.navigate(["api"]);
        break;
      case 'flood':
        this.myRoute.navigate(['api/rt']);
        break;
      case 'asset':
        //console.log( environment.assetInterfaceUrl)
        window.open(environment.assetInterfaceUrl, "_blank")
        //window.location.href = environment.assetInterfaceUrl
      case 'supply':
        window.open(environment.supplyUrl, "_blank")
        //window.location.href = environment.supplyUrl
      default:

        break;
    }

  }

}
